<template>
  <div>
    <div class="up-box">
      <template v-if="value[0]">
        <template v-if="type === 'image'">
          <div
            class="avatar-box"
            @mouseleave="mouseleaveFun()"
            @mouseenter="mouseenterFun(index)"
            v-for="(item, index) of value"
            :key="index"
          >
            <img class="avatar-img" :src="item" alt="" />
            <div
              class="avatarMask"
              :class="{ active: imgActive == index }"
            ></div>
            <div class="item-actions" :class="{ active: imgActive == index }">
              <div class="item-icon" @click="handlePictureCardPreview(item)">
                <i class="color-w el-icon-zoom-in"></i>
              </div>
              <div class="item-icon" @click="handleRemove('img', index)">
                <i class="color-w el-icon-delete"></i>
              </div>
            </div>
          </div>
        </template>
        <div class="videoBox" v-else>
          <video :width="300" :height="150" controls="controls" preload="auto">
            <source :data-src="value" :src="value" type="video/mp4" />
          </video>
          <div>
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="handleRemove('video')"
              >删除视频</el-button
            >
          </div>
        </div>
      </template>

      <div v-if="showUpBtn">
        <el-upload
          v-if="type === 'image'"
          :limit="limit"
          accept="image/*"
          :action="''"
          list-type="picture-card"
          :show-file-list="false"
          :before-upload="beforeUploadFn"
          :http-request="materialUpload"
          :file-list="fileList"
          :width="width"
          :height="height"
          multiple
          :on-exceed="onExceed"
        >
          <i slot="default" class="el-icon-plus"></i
        ></el-upload>
        <!-- <div @click="choseFile" class="img-item">
          <i class="el-icon-plus"></i>
        </div> -->
        <el-upload
          v-if="type === 'video'"
          :limit="limit"
          accept="video/*"
          :action="''"
          list-type="picture-card"
          :before-upload="beforeUploadFn"
          :http-request="materialUpload"
          :file-list="fileList"
          :show-file-list="false"
          :width="width"
          :height="height"
          :on-exceed="onExceed"
          multiple
        >
          <i slot="default" class="el-icon-plus"></i
        ></el-upload>
      </div>
    </div>
    <div v-if="tips" class="tips">{{ tips }}</div>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <el-dialog
      width="70%"
      :visible.sync="showFileList"
      :append-to-body="true"
      @close="closeFileList"
    >
      <FileList
        @emit-choose="chooseSucess"
        ref="fileRef"
        :upfileList="urlS"
        :num="limit"
        :fileType="type"
        type="choose"
      />
    </el-dialog>
  </div>
</template>
<script>
import { uploadtApi } from "@/api/filePage/filePage.js";
export default {
  name: "UploadList",
  props: {
    // 素材数据
    value: { type: Array, default: () => [] },
    // 素材类型
    type: { type: String, default: "image" },
    // 宽度
    width: { type: Number, default: 100 },
    // 宽度
    height: { type: Number, default: 100 },
    limitSize: { type: Number, default: 104857600 }, // 单位 kb , 默认限制100M
    limit: { type: Number, default: 1 }, // 上传个数
    tips: { type: String, default: "" }, //
    note: { type: String, default: "" },
  },
  model: {
    prop: "value",
    event: "myChange",
  },
  data() {
    return {
      dialogImageUrl: "", // 图片预览src
      dialogVisible: false, // 图片预览弹窗
      imgActive: -1,
      fileList: [],
      urlS: [],
      showFileList: false,
    };
  },
  computed: {
    showUpBtn() {
      return this.limit - this.value.length;
    },
  },
  watch: {
    urlS: function (val) {
      this.$emit("myChange", val);
    },
    // value: function (val) {
    //   console.log(val,'val');
    //   this.urlS = val;
    // },
  },
  created() {
    this.urlS = this.value;
  },
  methods: {
    beforeUploadFn(file) {
      const _isLimit = file.size / 1024 > this.limitSize;
      const limit = this.limitSize / 1024 / 2024;
      if (_isLimit) {
        this.$message.error("上传文件大小不能超过" + limit + "M");
        return false;
      }
      return true;
    },
    //自定义上传方法
    materialUpload(e) {
      let materialFile = e.file;
      let limit100M = materialFile.size / 1024 / 1024 < 100;
      if (limit100M) {
        let fd = new FormData();
        fd.append("file", materialFile);
        uploadtApi(fd)
          .then((res) => {
            if (res.data.data.url) {
              this.urlS.push(res.data.data.url);
              this.$emit("change-up", {
                type: "push",
                index: this.urlS.length - 1,
              });
            }
          })
          .catch((err) => {
            this.fileList = [];
          });
      } else {
        this.$message({
          message: "上传文件过大,上传文件不能超过100M",
          type: "error",
        });
      }
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    mouseleaveFun() {
      this.imgActive = -1;
    },
    mouseenterFun(index) {
      this.imgActive = index;
    },
    handleRemove(type = "img", index) {
      let str = type === "img" ? "图片" : "视频";
      this.$confirm(`确定要删除当前${str}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(index, "index");
          this.urlS.splice(index, 1);
          this.$emit("change-up", {
            type: "splice",
            index
          });
        })
        .catch(() => {});
    },
    choseFile() {
      // this.showFileList = true;
    },
    chooseSucess(list) {
      this.urlS = list;
      this.showFileList = false;
    },
    closeFileList() {
      this.$refs.fileRef.chooseList = JSON.parse(JSON.stringify(this.urlS));
      this.$refs.fileRef.setChooseStatus();
    },
    onExceed(files, fileList) {
      this.$message.error('最多选择'+ this.limit + '张,当前选择'+ (files.length + fileList.length) + '张' )
      console.log(files,'files');
      console.log(fileList,'fileList');
    }
  },
};
</script>
<style lang="scss" scoped>
.up-box /deep/ {
  display: flex;
  flex-wrap: wrap;
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.avatar-box {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  .avatar-img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .avatarMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: all 0.6s;
  }
  .active.avatarMask {
    opacity: 0.5;
  }
  .item-actions {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    transform: translateY(300px);
    transition: all 0.6s;
    .color-w {
      color: white;
      font-size: 20px;
    }
  }
  .item-actions.active {
    transform: translateY(0);
  }
}
.videoBox {
  position: relative;
}
.tips {
  color: rgb(131, 120, 120);
  font-size: 12px;
  line-height: 32px;
}
.img-item {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
}
.el-dialog__wrapper /deep/ {
  .el-dialog__body {
    padding-bottom: 15px !important;
  }
}
</style>